section ul li {
  background: url("bulletpoint-color.7b3e1f19.svg") 0 .35em no-repeat;
  padding-left: 35px;
  list-style-type: none;
}

section p a {
  color: #ff4deb !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.pc-label.submit-message {
  color: #fff;
}

.header-nav {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  max-width: none !important;
  width: auto !important;
}

.header-nav > li {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.header-nav > li > a {
  padding: .7em 1em !important;
}

.header-nav > li > ul {
  z-index: 9999;
}

@media (min-width: 505px) {
  .breakpoint-mode.mobile-enabled .header-logo {
    padding: .7em 1em;
  }
}

.flex-1 {
  flex: 1;
}

.background-repeat {
  background-position-y: top !important;
  background-repeat: repeat !important;
  background-size: auto !important;
}

@supports (object-fit: contain) {
  .logo-slider .swiper-slide img {
    object-fit: contain !important;
  }
}

.logo-slider .swiper-slide img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  height: auto !important;
}

@supports (object-fit: contain) {
  .logo-slider .swiper-slide img {
    object-fit: contain !important;
  }
}

/*# sourceMappingURL=index.css.map */
