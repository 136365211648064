/**************/
/* GLOBAL CSS */
/**************/
/*
	This file contains some global CSS styles that are included on every page of the website.
	You can use this to customize some parts of the website that are not customizable with Pagecloud, or even fix some styling issues.
*/


/* AUTO: Custom unordered list bulletpoints */
section ul li {
	list-style-type: none;
	padding-left:    35px;
	background:      url('./assets/bulletpoint-color.svg') no-repeat left 0.35em;
}


/* AUTO: Make links colored */
section p a {
	color: #ff4deb !important;
}


/* AUTO: Hide annoying reCaptcha badge */
.grecaptcha-badge {
	visibility: hidden;
}


/* AUTO: Correctly color the form submit message */
.pc-label.submit-message {
	color: #fff;
}


/* Makes header more responsive */
.header-nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: none !important;
	width: auto !important;
}

.header-nav > li {
	margin-right: 0 !important; /* We already have padding, no need for this */
	margin-left: 0 !important;
}

.header-nav > li > a {
	padding: 0.7em 1em !important;
}

.header-nav > li > ul {
	z-index: 9999; /* Bring the dropdown box to the front */
}

@media (min-width: 505px) {
	.breakpoint-mode.mobile-enabled .header-logo {
		padding: 0.7em 1em; /* Makes the logo more aligned with the nav */
	}
}


/* Little helper to make an element take up remaining space */
.flex-1 {
	flex: 1;
}


/* Make section backgrounds repeat and not scale up */
.background-repeat {
	background-repeat: repeat !important;
	background-size: auto !important;
	background-position-y: top !important;
}


/* Used for properly displaying the logos in the logo sliders, because the Pagecloud galleries normally stretch the logos out */
.logo-slider .swiper-slide img {
	max-height: 100%;
	max-width: 100%;
	height: auto !important;
	width: auto;
	margin: 0 auto;
}

@supports (object-fit: contain) {
	.logo-slider .swiper-slide img {
		object-fit: contain !important;
	}
}

/* Overlay a gradient  */
.logo-slider .swiper-slide img {
	max-height: 100%;
	max-width: 100%;
	height: auto !important;
	width: auto;
	margin: 0 auto;
}

@supports (object-fit: contain) {
	.logo-slider .swiper-slide img {
		object-fit: contain !important;
	}
}